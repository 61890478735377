<template>
  <div>
    <div class="flex mt-[50px] items-end justify-end mb-[50px]">
      <div
        class="max-w-52 mr-auto text-sm font-medium"
        v-if="chatStore.activeAiModel.tasks.get.hasTags"
      >
        <Label for="filter" class="text-left block">Filter by tags</Label>
        <Input
          id="filter"
          type="text"
          placeholder="Tag name..."
          v-model="filterPhrase"
          class="bg-gray-100 shadow-light border-none mt-3"
        />
      </div>

      <div>
        <Button
          type="submit"
          class="bg-accent hover:bg-accent"
          @click="tasksStore.refreshTasks()"
          >Refresh</Button
        >
      </div>
    </div>

    <div
      class="max-h-[calc(100dvh-640px)] lg:max-h-[calc(100dvh-580px)] min-h-[500px] overflow-y-auto h-full"
    >
      <Table>
        <TableHeader>
          <TableRow class="items-center">
            <TableHead
              class="text-center font-bold"
              v-for="tableHeader in chatStore.activeAiModel.tasks.tableHeaders"
              :key="tableHeader"
              >{{ tableHeader }}</TableHead
            >

            <TableHead class="text-center font-bold">Delete</TableHead>
          </TableRow>
        </TableHeader>
        <div
          v-if="isTasksLoading"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <img
            src="@/assets/icons/loading-icon.svg"
            alt="Loading AI Response"
            class="w-20"
          />
        </div>
        <TableBody v-else>
          <TableRow v-for="task in filteredTasks" :key="task.id">
            <TableCell class="font-medium text-center">{{ task.id }}</TableCell>
            <TableCell class="text-center">{{ task.status }}</TableCell>
            <TableCell v-if="task.source">{{ task.source }}</TableCell>
            <TableCell v-if="task.title" class="text-center">{{ task.title }}</TableCell>
            <TableCell>{{ task.query }}</TableCell>
            <TableCell v-if="task.tag">{{ task.tag }}</TableCell>
            <TableCell class="text-center">{{ formatDate(task.created_at) }}</TableCell>
            <TableCell class="text-center">
              <button @click="removeTask(task.id)">
                <img
                  src="@/assets/icons/trash-icon.svg"
                  alt="Delete Task"
                  class="max-w-[24px]"
                />
              </button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </div>
</template>

<script setup>
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useTasksStore } from '@/stores/tasksStore';
import { useChatStore } from '@/stores/chatStore';
import { onMounted, ref, watch, computed } from 'vue';
import { formatDate } from '@/lib/utils';
import { useToast } from '@/components/ui/toast/use-toast';
import { Button } from '@/components/ui/button';
import { storeToRefs } from 'pinia';

const { toast } = useToast();

const tasksStore = useTasksStore();
const { tasks  } = storeToRefs(useTasksStore());
const chatStore = useChatStore();

const filterPhrase = ref('');
const isTasksLoading = ref(true);

onMounted(async () => {
  isTasksLoading.value = true;

  await tasksStore.refreshTasks();

  isTasksLoading.value = false;
});

const filteredTasks = computed(() => {
  if (!tasks.value) {
    return [];
  }

  return tasks.value.filter((task) => {
    if (filterPhrase.value === '') {
      return true;
    }

    return task.tag.toLowerCase().includes(filterPhrase.value.toLowerCase());
  });
});

async function removeTask(taskId) {
  try {
    tasks.value = tasks.value.filter((task) => task.id !== taskId);
    await tasksStore.updateTask({ id: taskId });
  } catch (error) {
    console.error(error);
    toast({
      title: 'Uh oh! Something went wrong.',
      description: 'If the problem persists please contact an admin.',
      variant: 'destructive'
    });
  }
}

watch(
  () => chatStore.activeAiModel,
  async () => {
    isTasksLoading.value = true;

    tasks.value = await tasksStore.getTasks();

    isTasksLoading.value = false;
  }
);
</script>
