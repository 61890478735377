import { defineStore } from 'pinia';
import axios from 'axios';
import { useChatStore } from './chatStore';
import { useToast } from '@/components/ui/toast/use-toast';

const { toast } = useToast();

export const useTasksStore = defineStore('tasksStore', {
  state: () => {
    return {
      tasks: []
    };
  },
  actions: {
    async getTasks() {
      try {
        const chatStore = useChatStore();

        const requestParams = {};

        chatStore.activeAiModel.tasks.get.params.forEach((param) => {
          requestParams[param.paramName] = param.paramValue;
        });

        const response = await axios.get(
          chatStore.activeAiModel.tasks.get.endpoint,
          {
            params: requestParams,
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );
        const data = response.data.data;

        return data;
      } catch (error) {
        console.error(error);
        toast({
          title: 'Uh oh! Something went wrong.',
          description: 'If the problem persists please contact an admin.',
          variant: 'destructive'
        });
      }
    },
    async getVendors() {
      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/availableVendors',
          {
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );
        const data = response.data.data;

        return data;
      } catch (error) {
        console.error(error);
        toast({
          title: 'Uh oh! Something went wrong.',
          description: 'If the problem persists please contact an admin.',
          variant: 'destructive'
        });
      }
    },
    async updateTask(task) {
      try {
        const chatStore = useChatStore();

        const requestParams = {};

        if (task.vendors) {
          requestParams.vendors = {};

          task.vendors.forEach((vendor) => {
            requestParams.vendors[vendor] = {
              tag: task.tag,
              query: task.query
            };
          });
        } else {
          if (task.id) {
            requestParams.task_id = parseFloat(task.id);
          }
          if (task.query) {
            requestParams.query = task.query;
          }
          if (task.tag) {
            requestParams.tag = task.tag;
          }
          if (task.title) {
            requestParams.title = task.title;
          }
          if (task.reportType) {
            requestParams.report_type = task.reportType;
          }
        }

        const response = await axios.post(
          chatStore.activeAiModel.tasks.update.endpoint,
          requestParams,
          {
            headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
          }
        );
        const data = response.data.data;

        return data;
      } catch (error) {
        console.error(error);
        toast({
          title: 'Uh oh! Something went wrong.',
          description: 'If the problem persists please contact an admin.',
          variant: 'destructive'
        });
      }
    },
    async refreshTasks() {
      this.tasks = await this.getTasks();
    }
  }
});
