<template>
  <div
    class="ai-models top-[120px] left-[28px] fixed z-40 text-xl font-bold lg:static lg:mt-[31px] lg:max-w-[256px]"
  >
    <AIDropdown :expandDown="true">
      <template #buttonTitle>
        <div class="px-14 py-1">
          <span class="whitespace-nowrap font-bold text-[24px]"
            >Active A.I.</span
          >
          <p class="text-xs font-bold text-[13px]">{{ activeAiModel.name }}</p>
        </div>
      </template>
      <template #dropdownContent>
        <div
          v-for="aiModel in aiModels"
          :key="aiModel"
          class="flex items-center justify-between mt-2 p-2 relative"
        >
          <input
            name="aiModels"
            class="radio-button"
            type="radio"
            :id="aiModel.name"
            :value="aiModel"
            v-model="activeAiModel"
          />
          <label :for="aiModel.name" class="cursor-pointer radio-label">{{
            aiModel.name
          }}</label>
        </div>
      </template>
    </AIDropdown>
  </div>
</template>

<script setup>
import AIDropdown from '@/components/chat/AIDropdown';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import { AI_MODELS } from '@/lib/aiModels';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const { activeAiModel } = storeToRefs(useChatStore());

const route = useRoute();

onMounted(() => {
  const isAiModelActive = aiModels.value.some(
    (aiModel) => aiModel.name === activeAiModel.value.name
  );

  if (isAiModelActive) {
    return;
  }

  activeAiModel.value = aiModels.value[0];
});

const aiModels = computed(() => {
  const aiModels = [];

  for (const aiModel of AI_MODELS) {
    if (!aiModel.chat && route.name !== 'Tasks') {
      continue;
    }

    aiModels.push(aiModel);
  }

  return aiModels;
});
</script>
