<template>
  <div ref="dropdown" class="relative">
    <div
      v-show="isDropdownOpen"
      :class="[
        'bg-secondary',
        'text-neutral-50',
        'p-2',
        'mt-1',
        'drop-shadow-lg',
        'absolute',
        'w-full',
        'z-0',
        ...dropdownStyles
      ]"
      @blur="toggleDropdown()"
    >
      <slot name="dropdownContent"></slot>
    </div>

    <button
      @click="toggleDropdown()"
      :class="[
        'bg-accent',
        'text-neutral-50',
        'p-2',
        'w-full',
        props.hasFullyRoundedBorder ? 'rounded-xl' : 'rounded-l-xl',
        'relative',
        'z-10',
        'shadow-light'
      ]"
    >
      <slot name="buttonTitle"></slot>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, computed } from 'vue';

const props = defineProps({
  hasFullyRoundedBorder: {
    type: Boolean,
    default: true
  },
  expandDown: {
    type: Boolean,
    default: false
  }
});

const isDropdownOpen = ref(false);
const dropdown = ref(null);

const dropdownStyles = computed(() => {
  if (props.expandDown) {
    return ['translate-y-full', 'bottom-4', 'rounded-b-lg'];
  }

  return ['bottom-[80%]', 'rounded-t-lg'];
});

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
}

onMounted(() => {
  document.addEventListener('click', closeDropdownOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', closeDropdownOutside);
});

function closeDropdownOutside(event) {
  if (dropdown.value && !dropdown.value.contains(event.target)) {
    isDropdownOpen.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
