import { defineStore } from 'pinia';
import axios from 'axios';
import { AI_MODELS } from '@/lib/aiModels';
import { useToast } from '@/components/ui/toast/use-toast';
import { TOAST_GENERIC_ERROR } from '@/lib/constants';

const { toast } = useToast();

const storedChatsJsonString = localStorage.getItem('chats');
let storedChats = [];
if (storedChatsJsonString) {
  storedChats = JSON.parse(storedChatsJsonString);
}

export const useChatStore = defineStore('chatStore', {
  state: () => {
    return {
      isAppLoading: true,
      chats: storedChats,
      activeChat: storedChats[0],
      chatPending: false,
      gptModels: null,
      activeGptModel: null,
      gptModelKValue: 1,
      activeSearchCategory: null,
      activeSearchResult: null,
      activeAiModel: AI_MODELS[0],
      searchRequestPending: false,
      isGoogleSearchOn: false
    };
  },
  getters: {
    searchCategories: (state) => {
      if (!state.activeAiModel.chat) {
        return [];
      }

      const { searchCategories } = state.activeAiModel.chat;

      state.activeSearchCategory = searchCategories[0];

      return searchCategories;
    }
  },
  actions: {
    async getAIResponse(message) {
      this.chatPending = true;
      const activeMessage = this.activeChat.messages.find(
        (activeChatMessage) => activeChatMessage.id === message.id
      );

      try {
        const activeChatEndpoint = this.activeAiModel.chat.endpoints.find(
          (endpoint) =>
            endpoint.searchCategory === this.activeSearchCategory.apiName
        );

        if (!activeChatEndpoint) {
          toast(TOAST_GENERIC_ERROR);

          return;
        }

        const requestParams = {
          prompt: message.user
        };

        activeChatEndpoint.params.forEach((param) => {
          if (this[param.stateName] === null) {
            return;
          }

          requestParams[param.apiName] = this[param.stateName];
        });

        const response = await axios.get(activeChatEndpoint.endpoint, {
          params: requestParams,
          headers: {
            'x-api-key': process.env.VUE_APP_API_KEY
          }
        });
        const data = response.data.data;

        activeMessage.aiModel = this.activeAiModel.name;
        activeMessage.ai = data.response;
        activeMessage.metaData = data.embeddingMetaData;

        this.chatPending = false;

        this.updateChatsCache();
      } catch (error) {
        console.error(error);

        activeMessage.ai =
          'Apologies, we encountered an error processing the AI response on the server. Please retry or reach out to the owner for assistance.';
        activeMessage.error = true;

        this.chatPending = false;
      }
    },
    async getGptModels() {
      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/supportedGPTmodels',
          {
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );

        const data = response.data.data;
        this.gptModels = data.gptModels;

        const defaultGptModel = this.gptModels.find(
          (gptModel) => gptModel.default
        );
        this.activeGptModel = defaultGptModel.name;

        this.isAppLoading = false;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);
      }
    },
    async getSearchResults(searchText) {
      this.searchRequestPending = true;

      try {
        const response = await axios.get(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/universalLookUp',
          {
            params: {
              category: this.activeSearchCategory.apiName,
              search: searchText
            },
            headers: {
              'x-api-key': process.env.VUE_APP_API_KEY
            }
          }
        );
        const data = response.data.data;

        this.searchRequestPending = false;

        return data;
      } catch (error) {
        console.error(error);
        toast(TOAST_GENERIC_ERROR);

        this.searchRequestPending = false;
      }
    },
    async submitUserFeedback(message) {
      try {
        const messageContent = `USER_QUESTION: "${message.user}" \n GPT_RESPONSE: "${message.ai}"`;

        const requestParams = {
          tag: 'ehg',
          content: messageContent,
          engine: message?.aiEngine ?? 'patent',
          feedback: true
        };

        await axios.post(
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/info/ehgUserFeedback',
          requestParams,
          {
            headers: { 'x-api-key': process.env.VUE_APP_API_KEY }
          }
        );

        message.hasSubmittedFeedback = true;

        this.updateChatsCache();
      } catch (error) {
        toast(TOAST_GENERIC_ERROR);
        throw new Error(error);
      }
    },
    updateChatsCache() {
      localStorage.setItem(
        'chats',
        JSON.stringify(
          this.chats.length > 10 ? this.chats.slice(-10) : this.chats
        )
      );
    },
    createNewChat() {
      const newChat = {
        id: this.chats.length,
        name: 'Title of Conversation',
        messages: [],
        createdDate: new Date(),
        lastUpdatedDate: new Date(),
        aiEngine: this.activeAiModel.engine
      };

      this.chats.push(newChat);
      this.activeChat = newChat;

      this.updateChatsCache();
    }
  }
});
