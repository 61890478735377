export const AI_MODELS = [
  {
    name: 'Patent',
    engine: 'patent',
    chat: {
      endpoints: [
        {
          name: 'Patent Search',
          searchCategory: 'tags',
          endpoint:
            'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/patent/patentSeach',
          params: [
            {
              apiName: 'gpt_model',
              stateName: 'activeGptModel'
            },
            {
              apiName: 'k',
              stateName: 'gptModelKValue'
            },
            {
              apiName: 'tag',
              stateName: 'activeSearchResult'
            },
            {
              apiName: 'google',
              stateName: 'isGoogleSearchOn'
            }
          ]
        },
        {
          name: 'Patent Communicate',
          searchCategory: 'patents',
          endpoint:
            'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/patent/patentCommunicate',
          params: [
            {
              apiName: 'gpt_model',
              stateName: 'activeGptModel'
            },
            {
              apiName: 'k',
              stateName: 'gptModelKValue'
            },
            {
              apiName: 'patent_id',
              stateName: 'activeSearchResult'
            },
            {
              apiName: 'google',
              stateName: 'isGoogleSearchOn'
            }
          ]
        }
      ],
      searchCategories: [
        {
          apiName: 'tags',
          readableName: 'Tag',
          resultName: 'tag'
        },
        {
          apiName: 'patents',
          readableName: 'Patent',
          resultName: 'patent_id'
        }
      ]
    },
    tasks: {
      update: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/updatePatentTasks',
        params: [
          {
            apiName: 'query',
            stateName: 'query',
            readableName: 'Query',
            description: null,
            inputType: 'text'
          },
          {
            apiName: 'tag',
            stateName: 'tag',
            readableName: 'Tag',
            description: null,
            inputType: 'text'
          }
        ]
      },
      get: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/taskList',
        params: [
          {
            paramName: 'table',
            paramValue: 'patents'
          }
        ],
        hasTags: true
      },
      tableHeaders: ['Id', 'Status', 'Query', 'Tag', 'Created Date']
    }
  },
  {
    name: 'Market',
    engine: 'market_data',
    chat: {
      endpoints: [
        {
          name: 'Market Communicate',
          searchCategory: 'market_data_sources',
          endpoint:
            'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/marketDataCommunicate',
          params: [
            {
              apiName: 'gpt_model',
              stateName: 'activeGptModel'
            },
            {
              apiName: 'k',
              stateName: 'gptModelKValue'
            },
            {
              apiName: 'source',
              stateName: 'activeSearchResult'
            },
            {
              apiName: 'google',
              stateName: 'isGoogleSearchOn'
            }
          ]
        }
      ],
      searchCategories: [
        {
          apiName: 'market_data_sources',
          readableName: 'Source',
          resultName: 'source'
        }
      ]
    },
    tasks: {
      update: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/updateVendorTasks',
        params: [
          {
            apiName: 'vendors',
            stateName: 'selectedVendors',
            readableName: 'Vendors',
            description: null,
            inputType: 'multiSelect'
          },
          {
            apiName: 'query',
            stateName: 'query',
            readableName: 'Query',
            description: null,
            inputType: 'text'
          },
          {
            apiName: 'tag',
            stateName: 'tag',
            readableName: 'Tag',
            description: null,
            inputType: 'text'
          }
        ]
      },
      get: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/marketData/taskList',
        params: [
          {
            paramName: 'table',
            paramValue: 'market_data'
          }
        ],
        hasTags: true
      },
      tableHeaders: ['Id', 'Status', 'Vendor', 'Query', 'Tag', 'Created Date']
    }
  },
  {
    name: 'Reporting',
    engine: 'reporting',
    chat: null,
    tasks: {
      update: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/reporting/updateReportTasks',
        params: [
          {
            apiName: 'report_type',
            stateName: 'reportType',
            readableName: 'Report Type',
            description: null,
            inputType: 'select',
            options: ['standard', 'patent']
          },
          {
            apiName: 'title',
            stateName: 'title',
            readableName: 'Title',
            description: null,
            inputType: 'text'
          },
          {
            apiName: 'query',
            stateName: 'query',
            readableName: 'Query',
            description: null,
            inputType: 'text'
          }
        ]
      },
      get: {
        endpoint:
          'https://suykeejg83.execute-api.us-east-1.amazonaws.com/prod/ehg/reporting/getAllReports',
        params: [],
        hasTags: false
      },
      tableHeaders: ['Id', 'Status', 'Title', 'Query', 'Created Date']
    }
  }
];
