<template>
  <div class="mt-8 lg:mt-10">
    <h2 class="font-bold text-base">Chat History</h2>

    <div class="flex flex-col gap-6 lg:gap-10 mt-4 lg:mt-8">
      <div v-if="todayChats.length > 0">
        <h3 class="font-bold">Today</h3>
        <ol class="flex flex-col gap-1.5 lg:gap-2.5 mt-3">
          <li v-for="chat in todayChats" :key="chat.id">
            <button
              :class="[
                'hover:text-accentBlue',
                { 'text-accentBlue': activeChat.id === chat.id }
              ]"
              @click="activeChat = chat"
            >
              {{ chat.name }}
            </button>
          </li>
        </ol>
      </div>

      <div v-if="last30DaysChats.length > 0">
        <h3 class="font-bold">Last 30 Days</h3>
        <ol class="flex flex-col gap-1.5 lg:gap-2.5 mt-3">
          <li v-for="chat in last30DaysChats" :key="chat.id">
            <button
              :class="[
                'hover:text-accentBlue',
                { 'text-accentBlue': activeChat.id === chat.id }
              ]"
              @click="activeChat = chat"
            >
              {{ chat.name }}
            </button>
          </li>
        </ol>
      </div>

      <div v-if="olderChats.length > 0">
        <h3 class="font-bold">Older</h3>
        <ol class="flex flex-col gap-1.5 lg:gap-2.5 mt-3">
          <li v-for="chat in olderChats" :key="chat.id">
            <button
              :class="[
                'hover:text-accentBlue',
                { 'text-accentBlue': activeChat.id === chat.id }
              ]"
              @click="activeChat = chat"
            >
              {{ chat.name }}
            </button>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

const { chats, activeChat, activeAiModel } = storeToRefs(useChatStore());

const chatsByAi = computed(() => {
  return chats.value.filter((chat) => {
    return chat.aiEngine === activeAiModel.value.engine;
  });
});

const todayChats = computed(() => {
  return chatsByAi.value.filter((chat) => {
    return (
      new Date(chat.createdDate).toLocaleDateString() ===
      new Date().toLocaleDateString()
    );
  });
});

const last30DaysChats = computed(() => {
  const last30Days = new Date();
  last30Days.setDate(last30Days.getDate() - 30);

  return chatsByAi.value.filter((chat) => {
    return (
      new Date(chat.createdDate) >= last30Days &&
      new Date(chat.createdDate).toLocaleDateString() !==
        new Date().toLocaleDateString()
    );
  });
});

const olderChats = computed(() => {
  const last30Days = new Date();
  last30Days.setDate(last30Days.getDate() - 30);

  return chatsByAi.value.filter((chat) => {
    return new Date(chat.createdDate) < last30Days;
  });
});

watch(
  () => activeAiModel.value,
  () => {
    activeChat.value = chatsByAi.value[0];
  }
);
</script>
