<template>
  <div
    class="mx-auto pb-4 mt-[12px] overflow-y-auto chat-messages h-full w-full"
  >
    <div
      v-for="(message, index) in chatStore.activeChat?.messages"
      :key="index"
      class="flex flex-col gap-[38px]"
    >
      <div class="mt-[38px]">
        <p class="text-xs text-gray-200 text-right">
          {{ user.name }}, {{ formatDate(message.createdDate) }}
        </p>
        <div class="rounded-lg bg-gray-100 px-5 py-3.5 mt-[4px] leading-5">
          <p class="text-black">
            {{ message.user }}
          </p>
        </div>
      </div>

      <div v-if="message.ai" class="mt-2 relative">
        <Dialog>
          <DialogTrigger class="absolute -translate-x-full -left-4 w-[20px]">
            <img :src="getThumbIcon(message)" alt="Submit positive feedback" />
          </DialogTrigger>

          <DialogContent v-if="message.hasSubmittedFeedback">
            <DialogHeader>
              <DialogTitle>Feedback Has Been Submitted</DialogTitle>
            </DialogHeader>

            <DialogDescription>
              The feedback for the selected response has already been submitted.
            </DialogDescription>

            <DialogFooter class="sm:justify-center">
              <DialogClose>
                <Button>Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>

          <DialogContent v-else>
            <DialogHeader>
              <DialogTitle>Submit User Feedback Confirmation</DialogTitle>
            </DialogHeader>

            <DialogDescription>
              Are you sure you want to submit that feedback as positive?
            </DialogDescription>

            <DialogFooter class="sm:justify-center">
              <DialogClose>
                <Button @click="onClick(message)">Submit</Button>
              </DialogClose>

              <DialogClose>
                <Button type="button" variant="secondary">Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <div>
          <p class="text-xs text-gray-200">
            EHG Inform, {{ formatDate(message.createdDate) }}
          </p>

          <p
            :class="[
              'text-black leading-5',
              { 'text-red-500': message.ai.error },
              'mt-[4px]'
            ]"
          >
            <span class="markdown" v-html="parseMarkdown(message.ai)"></span>
          </p>
        </div>
      </div>

      <div v-else class="flex items-center justify-center">
        <p>Loading AI Response...</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useChatStore } from '@/stores/chatStore';
import { watch, nextTick } from 'vue';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { marked } from 'marked';
import { useAuth0 } from '@auth0/auth0-vue';
import { formatDate } from '@/lib/utils';

const { user } = useAuth0();

const chatStore = useChatStore();

function scrollChat() {
  const chatMessages = document.querySelector('.chat-messages');
  chatMessages.scrollTo({
    top: chatMessages.scrollHeight,
    behavior: 'smooth'
  });
}

async function onClick(chat) {
  try {
    await chatStore.submitUserFeedback(chat);
  } catch (error) {
    console.error(error);
  }
}

function getThumbIcon(message) {
  return message.hasSubmittedFeedback
    ? require('@/assets/icons/thumbs-up-active.svg')
    : require('@/assets/icons/thumbs-up-inactive.svg');
}

// Marked Renderer extension to open links in new tab
const renderer = new marked.Renderer();
// eslint-disable-next-line no-unused-vars
renderer.link = function (href, title, text) {
  const link = marked.Renderer.prototype.link.apply(this, arguments);
  return link.replace('<a', "<a target='_blank'");
};

marked.setOptions({
  renderer: renderer
});

function parseMarkdown(message) {
  return marked.parse(message);
}

watch(chatStore.chats, () => {
  nextTick(() => {
    scrollChat();
  });
});

watch(
  () => chatStore.isAppLoading,
  () => {
    if (chatStore.isAppLoading) {
      return;
    }

    scrollChat();
  }
);
</script>

<style>
.markdown a {
  color: revert;
}
</style>
