<script setup>
import { defineProps } from 'vue';
import { cn } from '@/lib/utils';

const props = defineProps({
  class: { type: null, required: false }
});
</script>

<template>
  <div class="relative w-full h-full">
    <table :class="cn('w-full caption-bottom text-sm', props.class)">
      <slot />
    </table>
  </div>
</template>
