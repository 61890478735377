<template>
  <div
    :class="[
      'app',
      'overflow-hidden',
      'flex',
      'flex-col',
      'lg:flex-row',
      'relative',
      { 'justify-between': $route.path === '/chat' }
    ]"
    v-if="isAuthenticated"
  >
    <Navbar />
    <div class="relative lg:w-full h-full">
      <ProfileIcon class="absolute right-4 top-4" v-if="!isMobile" />
      <router-view class="h-full"/>
    </div>
    <Toaster />
  </div>
</template>

<script setup>
import Navbar from '@/components/Navbar';
import Toaster from '@/components/ui/toast/Toaster';
import { useAuth0 } from '@auth0/auth0-vue';
import ProfileIcon from '@/components/auth/ProfileIcon';
import { useAppStore } from '@/stores/appStore';
import { storeToRefs } from 'pinia';

const { isMobile } = storeToRefs(useAppStore());
const { isAuthenticated } = useAuth0();
</script>

<style>
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.app {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.radio-button {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.radio-label::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  z-index: 30;
  width: 17px;
  height: 17px;
  background-color: #ffffff;
}

.radio-button:checked + .radio-label::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  z-index: 40;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #153d6c;
}
</style>
