<template>
  <div class="chat-view relative w-full">
    <div
      class="absolute h-full w-full top-0 bottom-0 right-0 left-0 bg-white z-10"
      v-if="chatStore.isAppLoading"
    >
      <div class="flex flex-col items-center justify-center h-full">
        <img src="@/assets/icons/loading-icon.svg" alt="" class="w-32" />
        <p>Loading...</p>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script setup>
import Chat from '@/components/chat/Chat';
import { useChatStore } from '@/stores/chatStore';
import { onMounted } from 'vue';

const chatStore = useChatStore();

onMounted(() => {
  if (chatStore.chats.length > 0) {
    return;
  }

  chatStore.createNewChat();
});
</script>

<style>
.chat-view {
  height: calc(100% - 78px);

  @media (min-width: 1024px) {
    height: 100%;
  }
}
</style>
