<template>
  <nav
    class="bg-secondary text-secondary-foreground shadow pl-[33px] pr-[27px] lg:max-w-[350px] lg:w-full"
  >
    <div
      class="flex items-center justify-between max-w-screen-2xl mx-auto py-4 lg:pt-8 lg:block"
    >
      <div class="flex items-center justify-between">
        <img
          src="@/assets/event-horizon-group-logo.png"
          alt="Event Horizon Group"
          class="max-w-[217px]"
        />

        <button v-if="!isMobile" @click="chatStore.createNewChat()">
          <img
            class="max-w-[20px]"
            src="@/assets/icons/pencil-icon.svg"
            alt="Start new conversation"
          />
        </button>
      </div>

      <div class="flex gap-8" v-if="!isAuthenticated">
        <LoginButton />
      </div>

      <div>
        <button @click="toggleMenu()" class="block lg:hidden h-[45px]">
          <div class="three col">
            <div
              :class="['hamburger', { 'is-active': isMenuOpen }]"
              id="hamburger"
            >
              <span class="line bg-accentBlue"></span>
              <span class="line bg-accentBlue"></span>
              <span class="line bg-accentBlue"></span>
            </div>
          </div>
        </button>

        <div
          class="bg-secondary absolute left-0 w-full lg:w-[350px] h-[calc(100%-61px)] z-50 pl-[33px] pr-[20px] flex flex-col justify-between overflow-y-auto"
          v-if="isAuthenticated && isMenuOpen"
          @click="toggleMenu()"
        >
          <div>
            <div v-if="isMobile" class="mt-6">
              <button
                class="flex items-center gap-4"
                @click="chatStore.createNewChat()"
              >
                <img
                  class="max-w-[20px]"
                  src="@/assets/icons/pencil-icon.svg"
                  alt=""
                />

                <span class="font-bold">New Conversation</span>
              </button>
            </div>

            <h2
              class="text-accent-foreground font-light text-base mt-6 lg:mt-10"
            >
              MENU
            </h2>
            <ul class="mt-2">
              <li
                class="font-normal text-accent-foreground uppercase mt-3 hover:text-accentBlue"
              >
                <RouterLink to="/chat">Chat</RouterLink>
              </li>
              <li
                class="font-normal text-accent-foreground uppercase mt-3 hover:text-accentBlue"
              >
                <RouterLink to="/tasks" v-if="hasAdminAccess">Tasks</RouterLink>
              </li>
              <li
                class="font-normal text-accent-foreground uppercase mt-3 hover:text-accentBlue"
              >
                <RouterLink to="/data" v-if="hasAdminAccess"
                  >Data Entry</RouterLink
                >
              </li>
            </ul>

            <ChatHistory />
          </div>

          <footer class="flex justify-between items-end pb-[20px]">
            <div>
              <p class="text-[10px] text-gray-200">Powered By</p>
              <img
                src="@/assets/event-horizon-group-logo.png"
                alt="Event Horizon Group"
                class="max-w-[217px] ml-[-10px] block"
              />
            </div>

            <div v-if="isMobile">
              <ProfileIcon />
            </div>
          </footer>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import ChatHistory from '@/components/chat/ChatHistory';
import { computed, ref, onMounted, watch, nextTick } from 'vue';
import { RouterLink } from 'vue-router';
import LoginButton from '@/components/auth/LoginButton';
import { useAuth0 } from '@auth0/auth0-vue';
import { useAppStore } from '@/stores/appStore';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import ProfileIcon from '@/components/auth/ProfileIcon.vue';

const MOBILE_WIDTH = 1024;
const isMenuOpen = ref(false);

const { isMobile } = storeToRefs(useAppStore());
const chatStore = useChatStore();
const { isAuthenticated, user } = useAuth0();

const ROLES = {
  ADMIN: 'admin'
};

const hasAdminAccess = computed(() => {
  if (!user.value.app_metadata) {
    return false;
  }

  return user.value.app_metadata?.roles?.includes(ROLES.ADMIN);
});

onMounted(async () => {
  window.addEventListener('resize', onResize);
  onResize();

  await nextTick();
  isMenuOpen.value = isMobile.value ? false : true;
});

async function onResize() {
  await nextTick();
  isMobile.value = window.innerWidth <= MOBILE_WIDTH;
}

function toggleMenu() {
  if (!isMobile.value) {
    return;
  }

  isMenuOpen.value = !isMenuOpen.value;
}

watch(isMobile, (isMobile) => {
  isMenuOpen.value = isMobile ? false : true;
});
</script>

<style scoped>
img {
  width: 302px;
}

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.hamburger .line {
  width: 56px;
  height: 4px;
  border-radius: 10px;
  display: block;
  margin: 8px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.line:nth-child(2) {
  margin-left: 12px;
}

.line:nth-child(3) {
  margin-left: 24px;
}

#hamburger.is-active .line {
  margin: 8px 0;
  width: 44px;
}

#hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(11px) rotate(45deg);
  -ms-transform: translateY(11px) rotate(45deg);
  -o-transform: translateY(11px) rotate(45deg);
  transform: translateY(11px) rotate(45deg);
}

#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  -o-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg);
}
</style>
