<template>
  <div class="flex m-auto items-center gap-2 w-full md:px-0">
    <form
      @submit.prevent="submitMessage"
      class="flex w-full h-full shadow-light"
    >
      <textarea
        cols="30"
        rows="1"
        @keypress.enter="handleEnter"
        v-model="userMessage"
        class="w-full resize-none bg-gray-100 text-gray-200 p-4 rounded"
        id="textareaInput"
        placeholder="Message EHG Inform"
      ></textarea>
      <label for="textareaInput" class="sr-only"
        >Compose Your Message to Interact with the AI</label
      >
      <Button
        type="submit"
        class="p-3 disabled:opacity-50 h-full rounded-none rounded-r bg-gray-100"
        :disabled="chatStore.chatPending"
      >
        <img
          v-if="chatStore.chatPending"
          src="@/assets/icons/blue-loading-icon.svg"
          alt="Loading AI Response"
          class="w-8"
        />
        <img
          v-else
          src="@/assets/icons/left-arrow-icon.svg"
          alt="Submit Chat"
        />
      </Button>
    </form>

    <!-- Google Search -->
    <Toggle
      aria-label="Toggle google search"
      @click="toggleGoogleSearch()"
      variant="outline"
      class="bg-accent h-full w-[76px] rounded-lg"
    >
      <img :src="googleIcon" alt="" class="w-[26px] pointer-events-none" />
    </Toggle>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useChatStore } from '@/stores/chatStore';
import { Toggle } from '@/components/ui/toggle';
import { Button } from '@/components/ui/button';

const chatStore = useChatStore();
const userMessage = ref('');

const submitMessage = () => {
  const message = {
    id: chatStore.activeChat.messages.length,
    user: userMessage.value,
    ai: '',
    createdDate: new Date(),
    aiEngine: chatStore.activeAiModel.engine
  };

  chatStore.activeChat.messages.push(message);
  userMessage.value = '';

  chatStore.getAIResponse(message);

  chatStore.activeChat.lastUpdatedDate = new Date();
};

const handleEnter = (event) => {
  if (chatStore.chatPending) {
    return;
  }

  if (!event.key === 'Enter' && event.shiftKey) {
    return;
  }
  event.preventDefault();

  submitMessage();
};

const googleIcon = computed(() => {
  if (chatStore.isGoogleSearchOn) {
    return require('@/assets/icons/google-active-icon.svg');
  }

  return require('@/assets/icons/google-inactive-icon.svg');
});

function toggleGoogleSearch() {
  chatStore.isGoogleSearchOn = !chatStore.isGoogleSearchOn;
}
</script>
