<template>
  <div class="flex items-center gap-4">
    <p class="text-gray-200 text-sm" v-if="!isMobile">
      Welcome, {{ user.name }}
    </p>
    <img
      :src="user.picture"
      :alt="`Profile image of ${user.name}`"
      class="max-w-[32px] rounded-full shadow-medium"
    />
  </div>
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { useAppStore } from '@/stores/appStore';
import { storeToRefs } from 'pinia';

const { isMobile } = storeToRefs(useAppStore());
const { user } = useAuth0();
</script>
