<template>
  <div
    class="ai-dropdowns relative flex flex-col lg:flex-row lg:items-end lg:gap-2 gap-1 mx-auto my-2 md:px-0 w-full"
  >
    <div class="flex gap-2 justify-center items-center">
      <!-- GPT Models -->
      <AIDropdown class="lg:min-w-[258px] w-full whitespace-nowrap">
        <template #buttonTitle>GPT Models: {{ activeGptModel }}</template>
        <template #dropdownContent>
          <div
            v-for="gptModel in gptModels"
            :key="gptModel.name"
            class="flex items-center justify-between mt-2 p-2 relative"
          >
            <input
              class="radio-button"
              name="gptModels"
              type="radio"
              :id="gptModel.name"
              :value="gptModel.name"
              v-model="activeGptModel"
            />
            <label :for="gptModel.name" class="radio-label cursor-pointer">{{
              gptModel.name
            }}</label>
          </div>
        </template>
      </AIDropdown>

      <!-- GPT Model K Values -->
      <AIDropdown class="w-1/3 lg:min-w-[96px] whitespace-nowrap">
        <template #buttonTitle>K Value: {{ gptModelKValue }}</template>
        <template #dropdownContent>
          <div
            v-for="kValue in activeGptModelDetails?.maxKvalue"
            :key="kValue"
            class="flex items-center justify-between mt-2 p-2 relative"
          >
            <input
              class="radio-button"
              name="kValues"
              type="radio"
              :id="kValue"
              :value="kValue"
              v-model="gptModelKValue"
            />
            <label :for="kValue" class="cursor-pointer radio-label">{{
              kValue
            }}</label>
          </div>
        </template>
      </AIDropdown>
    </div>

    <!-- Search Categories -->
    <Search v-if="hasSearchParams" />
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import AIDropdown from '@/components/chat/AIDropdown';
import Search from '@/components/chat/Search';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';

const chatStore = useChatStore();
const { gptModels, activeGptModel, gptModelKValue, activeAiModel } =
  storeToRefs(useChatStore());

onMounted(() => {
  chatStore.getGptModels();
});

const activeGptModelDetails = computed(() => {
  if (!gptModels.value || !activeGptModel.value) {
    return;
  }

  return gptModels.value.find((model) => model.name === activeGptModel.value);
});

const hasSearchParams = computed(() => {
  if (!activeAiModel.value || !activeAiModel.value.chat) {
    return false;
  }

  return activeAiModel.value.chat.endpoints.some((endpoint) =>
    endpoint.params.some((param) => param.stateName === 'activeSearchResult')
  );
});

watch(activeGptModel, () => {
  gptModelKValue.value = 1;
});
</script>
