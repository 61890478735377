<template>
  <div class="chat-header pt-[141px] lg:pt-[41px]">
    <div class="flex items-center gap-2">
      <h2
        v-if="!isEditing"
        @click="startEditing"
        class="text-xl text-black-100 font-bold cursor-pointer hover:opacity-80"
      >
        {{ activeChat?.name || 'Title of Chat Conversation' }}
      </h2>

      <input
        v-else
        v-model.trim="editableChatName"
        @blur="saveChatName"
        @keyup.esc="cancelEdit"
        ref="titleInput"
        type="text"
        class="text-xl text-black-100 font-bold bg-transparent border-b border-gray-300 focus:outline-none focus:border-blue-500 w-full"
        :placeholder="'Enter chat title'"
      />
    </div>

    <div class="text-gray-200 text-xs mt-2.5">
      <p>Created {{ formatDate(activeChat?.createdDate) }}</p>
      <p>Last Updated {{ formatDate(activeChat?.lastUpdatedDate) }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import { formatDate } from '@/lib/utils';

const chatStore = useChatStore();
const { activeChat } = storeToRefs(chatStore);

const isEditing = ref(false);
const editableChatName = ref('');
const titleInput = ref(null);

const startEditing = () => {
  editableChatName.value = activeChat.value.name || '';
  isEditing.value = true;
  // Focus the input on next tick after it's rendered
  nextTick(() => {
    titleInput.value?.focus();
  });
};

const saveChatName = () => {
  activeChat.value.name = editableChatName.value;
  isEditing.value = false;

  chatStore.updateChatsCache();
};

const cancelEdit = () => {
  isEditing.value = false;
  editableChatName.value = activeChat.value.name || '';
};
</script>
